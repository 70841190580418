@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-phone-input {
  display: flex;
  align-items: flex-start;
}
::v-deep .v-phone-input__country.v-input {
  min-width: 0;
  max-width: 5rem;
}
::v-deep .v-phone-input__phone.v-input {
  flex-grow: 1;
}
